import React from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "../useMediaQuery";
import { getTrans as t } from "../Translation";

const Navbar = ({ sNav, closed }) => {
	let isMobile = useMediaQuery("(max-width: 63.9375em)");

	const activeLink = isMobile ? null : { color: "red" };

	return (
		<div className={sNav}>
			<ul onClick={closed}>
				<li>
					<NavLink activeStyle={activeLink} to="/" exact>
						<p>{t("home")}</p>
					</NavLink>
				</li>
				<li>
					<NavLink activeStyle={activeLink} to="/about">
						<p>{t("about")}</p>
					</NavLink>
				</li>
				<li>
					<NavLink activeStyle={activeLink} to="/partners">
						<p>{t("partners")}</p>
					</NavLink>
				</li>
				<li>
					<NavLink activeStyle={activeLink} to="/services">
						<p>{t("services")}</p>
					</NavLink>
				</li>
				<li>
					<NavLink activeStyle={activeLink} to="/references">
						<p>{t("references")}</p>
					</NavLink>
				</li>
				<li>
					<NavLink activeStyle={activeLink} to="/certificates">
						<p>{t("certificates")}</p>
					</NavLink>
				</li>
				<li>
					<NavLink activeStyle={activeLink} to="/contacts">
						<p>{t("contacts")}</p>
					</NavLink>
				</li>
			</ul>
		</div>
	);
};

export default Navbar;
