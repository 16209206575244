import React from "react";
import { useQuery } from "react-query";
import s from "../styles/Partners.module.css";
import { getTrans as t } from "../components/Translation";

const fetchPartners = async () => {
	const res = await fetch(`http://alfar.corn.az/api/home`, {
		cacheTime: 60000,
		refetchOnmount: false,
	});

	return res.json();
};

const Partners = () => {
	const { data } = useQuery("Partners", fetchPartners);

	return (
		<div className={s.partners}>
			<h1>{t("partners")}</h1>
			<div className={s.container}>
				{data &&
					data[5].map((cur, index) => {
						return (
							<div className={s.partner} key={index}>
								<img
									src={
										"http://alfar.corn.az/public/uploads/img/partners/" +
										cur.image
									}
									alt=""
									width="100%"
								/>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default Partners;
