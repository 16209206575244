import React from "react";
import { useQuery } from "react-query";
import s from "../styles/Certificates.module.css";
import { getTrans as t } from "../components/Translation";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const fetchCertificates = async () => {
	const res = await fetch(`http://alfar.corn.az/api/home`, {
		cacheTime: 60000,
		refetchOnmount: false,
	});

	return res.json();
};

const Certificates = () => {
	const { data } = useQuery("certificates", fetchCertificates);
	return (
		<SimpleReactLightbox>
			<div className={s.certificates}>
				<h1>{t("certificates")}</h1>
				<SRLWrapper>
					<div className={s.certificates_container}>
						{data &&
							data[6].map((cur, index) => {
								return (
									<div className={s.certificate} key={index}>
										<img
											src={
												"http://alfar.corn.az/public/uploads/catalogs/" +
												cur.fileAZ
											}
											alt=""
											width="100%"
										/>
									</div>
								);
							})}
					</div>
				</SRLWrapper>
			</div>
		</SimpleReactLightbox>
	);
};

export default Certificates;
