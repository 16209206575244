import React from "react";
import { getTrans as t } from "../components/Translation";
import { getMultiLang as ml } from "../components/MultiLang";
import s from "../styles/Services.module.css";
import { svgs } from "../components/svg";
import { useQuery } from "react-query";
import { Link, useRouteMatch } from "react-router-dom";

const fetchServices = async () => {
	const res = await fetch(`http://alfar.corn.az/api/home`, {
		cacheTime: 60000,
		refetchOnmount: false,
	});

	return res.json();
};

const Services = () => {
	const { data } = useQuery("services", fetchServices);
	const { url } = useRouteMatch();

	return (
		<div className={s.services}>
			<h1>{t("services")}</h1>
			<div className={s.categories}>
				{data &&
					data[1].map((cur, index) => (
						<Link to={`${url}/${cur.slug}`} key={cur.id}>
							<div className={s.category}>
								<div className={s.svg_container}>{svgs[index]}</div>
								<h4>{ml(cur.name_en, cur.name_az, cur.name_ru)}</h4>
							</div>
						</Link>
					))}
			</div>
		</div>
	);
};

export default Services;
