import React from "react";
import { MdLocationOn, MdPhoneIphone, MdMail } from "react-icons/md";
import { getTrans as t } from "../components/Translation";
import s from "../styles/Contacts.module.css";

const contacts = () => {
	return (
		<div className={s.contacts}>
			<h1>{t("contacts")}</h1>
			<div className={s.connection}>
				<div className={s.infos}>
					<div className={s.location}>
						<MdLocationOn />
						<p>{t("address")}</p>
					</div>
					<div className={s.phone}>
						<MdPhoneIphone />
						<p>{t("addressPhone")}: (+994 12) 440 63 88</p>
					</div>
					<div className={s.mail}>
						<MdMail />
						<p>office@alfa-r.az</p>
					</div>
				</div>
				<div className={s.contact_form_map}>
					<form>
						<input type="text" placeholder={t("fullname")} />
						<input type="text" placeholder={t("phone")} />
						<input type="email" placeholder="Email" required />
						<textarea type="text" placeholder={t("mail")}></textarea>
						<button>{t("send")}</button>
					</form>
					<div className={s.map}>
						{/* eslint-disable-next-line */}
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d759.6500529558507!2d49.83746128389543!3d40.395552394314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d7b1fca5fd9%3A0x8af2a2015e0057e3!2zNDYgRmV5enVsbGEgUWFzxLFtemFkyZksIEJha8SxLCDQkNC30LXRgNCx0LDQudC00LbQsNC9!5e0!3m2!1sru!2s!4v1623073126769!5m2!1sru!2s"
							width="100%"
							height="100%"
							frameBorder="0"
							scrolling="no"
							marginHeight="0"
							marginWidth="0"
						></iframe>
					</div>
				</div>
			</div>
		</div>
	);
};

export default contacts;
