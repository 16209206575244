import React, { useMemo, useRef } from "react";
import Services from "../components/Home/Services";
import ReferenceSection from "../components/Home/ReferenceSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getTrans as t } from "../components/Translation";
import { getMultiLang as ml } from "../components/MultiLang";
import SwiperCore, { Autoplay, Lazy, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/effect-cube/effect-cube.min.css";
import "swiper/swiper-bundle.css";
import s from "../styles/Home.module.css";
import { useMediaQuery } from "../components/useMediaQuery";
import { useQuery } from "react-query";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation, Autoplay, Lazy, Scrollbar]);

const fetchSlider = async () => {
	const res = await fetch(`http://alfar.corn.az/api/home`, {
		cacheTime: 60000,
		refetchOnmount: false,
	});

	return res.json();
};

const Home = () => {
	const { data } = useQuery("home", fetchSlider);
	const history = useHistory();
	const { url } = useRouteMatch();
	const swiperRef = useRef(null);
	let isMobile = useMediaQuery("(max-width: 63.9375em)");
	return (
		<div className={s.container}>
			<div
				className={s.slide_container}
				onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
				onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
			>
				<Swiper
					className={s.swiper}
					spaceBetween={8}
					ref={swiperRef}
					navigation
					loop={true}
					lazy
					autoplay={{ delay: 3000 }}
					slidesPerView={1}
					onSwiper={(swiper) => console.log(swiper)}
				>
					{data &&
						data[3].map((img) => (
							<SwiperSlide key={img.id}>
								<LazyLoadImage
									alt={img.id}
									className={s.home_slide}
									src={
										"http://alfar.corn.az/public/uploads/slider/" + img.image
									}
									width="100%"
								/>
								<h1 className={s.slider_title}>
									{ml(img.name_en, img.name_az, img.name_ru)}
								</h1>
							</SwiperSlide>
						))}
				</Swiper>
			</div>
			<Swiper
				className={s.service_container}
				spaceBetween={8}
				scrollbar={{ draggable: true }}
				observer={true}
				slidesPerView={isMobile ? 2 : 4}
			>
				{data &&
					data[1].map((cur, index) => (
						<SwiperSlide key={cur.id} style={{ paddingBottom: "15px" }}>
							<Link to={`${url}services/${cur.slug}`}>
								<Services
									id={index}
									key={index}
									content={ml(cur.name_en, cur.name_az, cur.name_ru)}
								/>
							</Link>
						</SwiperSlide>
					))}
			</Swiper>
			<div className={s.reference_section}>
				<h1>{t("references")}</h1>
				<div className={s.reference_imgs}>
					{useMemo(
						() =>
							data &&
							data[2]
								.slice(0, 4)
								.map((cur, index) => (
									<ReferenceSection
										id={cur.id}
										key={index}
										imgR={
											"http://alfar.corn.az/public/uploads/img/projects/" +
											cur.image
										}
										imgAlt={ml(cur.name_en, cur.name_az, cur.name_ru)}
										nameR={ml(cur.name_en, cur.name_az, cur.name_ru)}
									/>
								)),
						[data]
					)}
				</div>
				<button onClick={() => history.push("/references")}>
					{t("allRef")}
				</button>
			</div>
		</div>
	);
};

export default Home;
