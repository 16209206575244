import React from "react";
import s from "../styles/About.module.css";
import { getTrans as t } from "../components/Translation";
import { getMultiLang as ml } from "../components/MultiLang";
import { useQuery } from "react-query";

const fetchAbout = async () => {
	const res = await fetch(`http://alfar.corn.az/api/home`, {
		cacheTime: 60000,
		refetchOnmount: false,
	});

	return res.json();
};

const About = () => {
	const { data } = useQuery("singleProjects", fetchAbout);
	return (
		<div className={s.about}>
			<h1>{t("about")}</h1>

			<div className={s.img_container}>
				<img
					src={
						data && "http://alfar.corn.az/public/uploads/" + data[4][0].image
					}
					alt=""
					width="100%"
				/>
			</div>
			<p>
				{data && ml(data[4][0].text_en, data[4][0].text_az, data[4][0].text_ru)}
			</p>
		</div>
	);
};

export default About;
