import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import "../styles/App.css";
import useScrollDirection from "./useScrollDirection";

const ScrollButton = () => {
	const scrollDirection = useScrollDirection();
	const [visible, setVisible] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;

		if (scrolled > 300 && scrollDirection === "up") {
			setVisible(true);
			return;
		} else if (scrolled <= 300 || scrollDirection === "down") {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: "0",
			behavior: "smooth",
		});
	};

	window.addEventListener("scroll", toggleVisible, false);

	return (
		<div
			onClick={scrollToTop}
			className={visible ? "totop active_totop" : "totop"}
		>
			<FaArrowUp />
		</div>
	);
};

export default ScrollButton;
