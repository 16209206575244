import React, { useState } from "react";
import { useMediaQuery } from "../useMediaQuery";
import { IoClose } from "react-icons/io5/index";
import { GiHamburgerMenu } from "react-icons/gi";
import Navbar from "./Navbar";
import s from "../../styles/Header.module.css";
import { Link, useHistory } from "react-router-dom";
import LOGO from "../../images/Alfa-logo-red.png";
import Upper from "./Upper";

const Header = () => {
	let isMobile = useMediaQuery("(max-width: 63.9375em)");
	const [nav, setNav] = useState(false);
	const [open, setOpen] = useState(false);
	const history = useHistory();
	const navOpen = () => {
		setNav(!nav);
	};

	const langChecker = (lang = "az") => {
		return lang !== localStorage.getItem("lang");
	};

	const langs = ["az", "en", "ru"];
	const myLang = langs.filter(langChecker);

	const langSwitcher = (e) => {
		localStorage.setItem("lang", e.target.innerText);
		history.replace(history.location.pathname);
		setOpen(false);
	};

	return (
		<div className={s.header}>
			<Upper
				toggle={() => setOpen(!open)}
				switchLang={
					open && (
						<div className={s.sub_langs}>
							{myLang.map((lang) => (
								<button type="submit" key={lang} onClick={langSwitcher}>
									{lang}
								</button>
							))}
						</div>
					)
				}
			/>
			<div className={s.lower}>
				<div className={s.lower_container}>
					<div className={s.logo}>
						<Link to="/">
							<img src={LOGO} alt="Logo" width="70px" height="50px" />
						</Link>
					</div>
					{isMobile ? (
						nav ? (
							<IoClose onClick={navOpen} />
						) : (
							<GiHamburgerMenu onClick={navOpen} />
						)
					) : (
						<Navbar sNav={s.nav} closed={() => setNav(false)} />
					)}
					{nav && isMobile ? (
						<Navbar
							sNav={s.nav + " " + s.opened}
							closed={() => setNav(false)}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Header;
