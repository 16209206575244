const en = {
	home: "Home",
	about: "About",
	services: "Services",
	partners: "Partners",
	references: "References",
	certificates: "Certificates",
	contacts: "Contacts",
	fullname: "Fullname",
	phone: "Phone",
	mail: "Mail",
	send: "Send",
	allRef: "All references",
	rights: "All rights reserved",
	aboutP: "/about",
	productsP: "/products",
	partnersP: "/partners",
	referencesP: "/references",
	certificatesP: "/certificates",
	contactsP: "/contacts",
	name: "Name",
	address: "Azerbaijan, AZ1007, Baku city, Feyzulla Gasimzade str., 9",
	addressPhone: "Tel/Fax",
	projectName: "Name",
	place: "Place",
	customer: "Customer",
	typeOfWorks: "Type of works",
	period: "Period",
};

export default en;
