import "./styles/App.css";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import about from "./pages/about";
import contacts from "./pages/contacts";
import certificates from "./pages/certificates";
import partners from "./pages/partners";
import references from "./pages/references";
import referencesDetail from "./pages/referencesDetail";
import services from "./pages/services";
import servicesDetail from "./pages/servicesDetail";
import home from "./pages/home";
import ScrollButton from "./components/ScrollButton";

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<div className="App">
				<Router>
					<Layout>
						<div className="round"></div>
						<div className="round"></div>
						<div className="round"></div>
						<div className="round"></div>
						<Switch>
							<Route path="/" component={home} exact></Route>
							<Route path="/about" component={about}></Route>
							<Route path="/contacts" component={contacts}></Route>
							
							<Route path="/services/:name" component={servicesDetail}></Route>
							<Route path="/services" component={services}></Route>
							<Route path="/certificates" component={certificates}></Route>
							<Route path="/partners" component={partners}></Route>
							<Route
								path="/references/:id"
								component={referencesDetail}
							></Route>
							<Route path="/references" component={references}></Route>
						</Switch>
						<ScrollButton />
					</Layout>
				</Router>
			</div>
		</QueryClientProvider>
	);
}

export default App;
