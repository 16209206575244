import React from "react";
import { getTrans as t } from "../components/Translation";
import s from "../styles/References.module.css";

const ReferencesText = ({
	name,
	place,
	customer,
	typeOfWorks,
	periodFrom,
	periodTo,
}) => {
	return (
		<div className={s.ref_right}>
			<p className={s.name}>
				{t("projectName")}: {name}
			</p>

			<p className={s.place}>
				{t("place")}: {place}
			</p>
			<p className={s.customer}>
				{t("customer")}: {customer}
			</p>
			<p className={s.type_of_works}>
				{t("typeOfWorks")}: {typeOfWorks}
			</p>
			<p className={s.period}>
				{t("period")}: {periodFrom}-{periodTo}
			</p>
		</div>
	);
};

export default ReferencesText;
