const ru = {
	home: "Главная страница",
	about: "О компании",
	services: "Сервисы",
	partners: "Партнеры",
	references: "Проекты",
	certificates: "Сертификаты",
	contacts: "Контакты",
	fullname: "Имя и фамилия",
	phone: "Контактный номер",
	mail: "Письмо",
	send: "Отправить",
	allRef: "Все проекты",
	rights: "Все права защищены",
	aboutP: "/о_компании",
	productsP: "/продукция",
	partnersP: "/партнеры",
	referencesP: "/проекты",
	certificatesP: "/сертификаты",
	contactsP: "/контакты",
	name: "Имя",
	address: "Азербайджан, AZ1007, г.Баку, ул. Фейзулла Гасымзаде 9",
	addressPhone: "Тел./Факс",
	projectName: "Название",
	place: "Размещение",
	customer: "Клиент",
	typeOfWorks: "Тип работы",
	period: "Период",
};

export default ru;
