const az = {
	home: "Ana səhifə",
	about: "Haqqımızda",
	services: "Xidmətlər",
	partners: "Tərəfdaşlar",
	references: "Referanslar",
	certificates: "Sertifikatlar",
	contacts: "Əlaqə",
	fullname: "Ad və soyad",
	phone: "Telefon",
	mail: "Məktub",
	send: "Göndər",
	allRef: "Bütün referanslar",
	rights: "Bütün hüquqlar qorunur",
	aboutP: "/haqqimizda",
	productsP: "/mehsullar",
	partnersP: "/terefdaslar",
	referencesP: "/proyektler",
	certificatesP: "/sertifikatlar",
	contactsP: "/elaqe",
	name: "Ad",
	address: "Azərbaycan, AZ1007, Bakı şəh., Feyzulla Qasımzadə küç., 9",
	addressPhone: "Tel./Faks",
	projectName: "Ad",
	place: "Yerləşdiyi yer",
	customer: "Müştəri",
	typeOfWorks: "İş növü",
	period: "Period",
};

export default az;
