import React from "react";
import { FaPhoneAlt, FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import s from "../../styles/Header.module.css";

const Upper = ({ toggle, switchLang }) => {
	return (
		<div className={s.upper}>
			<div className={s.upper_container}>
				<div className={s.contact}>
					<div className={s.phone}>
						<FaPhoneAlt />
						<p>+994 12 440 63 88</p>
					</div>
					<div className={s.mail}>
						<IoMdMail />
						<p>office@alfa-r.az</p>
					</div>
				</div>
				<div className={s.soc_lang}>
					<div className={s.soc}>
						<div className={s.singleSvg}>
							<a
								href="https://www.facebook.com/AlfaR.Enginnering"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaFacebook />
							</a>
						</div>
						<div className={s.singleSvg}>
							<a
								href="https://www.instagram.com/alfa_r_engineering/?hl=ru"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaInstagram />
							</a>
						</div>
						<div className={s.singleSvg}>
							<a href="#">
								<FaYoutube />
							</a>
						</div>
					</div>
					<div className={s.lang}>
						<div className={s.current} onClick={toggle}>
							<p>
								{localStorage.getItem("lang")
									? localStorage.getItem("lang")
									: "az"}
							</p>
						</div>
						{switchLang}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Upper;
