import React, { useEffect, useState } from "react";
import s from "../styles/Reference.module.css";
import ReferencesText from "../components/ReferencesText.js";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { getTrans as t } from "../components/Translation";
import { getMultiLang as ml } from "../components/MultiLang";
import { useRouteMatch } from "react-router";

const ReferencesDetail = () => {
	const [data, setData] = useState([]);
	const { url } = useRouteMatch();
	const urlId = url.slice(12);

	useEffect(() => {
		fetch(`http://alfar.corn.az/api/projects/${urlId}`)
			.then((res) => res.json())
			.then((result) => setData(result));
	}, [urlId]);
	const [loading, setLoading] = useState(true);

	return (
		<SimpleReactLightbox>
			<div className={s.reference}>
				<h1>{t("references")}</h1>
				<div className={s.text_section}>
					<ReferencesText
						name={
							data[1] && ml(data[1].name_en, data[1].name_az, data[1].name_ru)
						}
						place={
							data[1] &&
							ml(data[1].place_en, data[1].place_az, data[1].place_ru)
						}
						customer={
							data[1] &&
							ml(data[1].customer_en, data[1].customer_az, data[1].customer_ru)
						}
						typeOfWorks={
							data[1] &&
							ml(
								data[1].type_works_en,
								data[1].type_works_az,
								data[1].type_works_ru
							)
						}
						periodFrom={data[1] && data[1].period_from}
						periodTo={data[1] && data[1].period_to}
					/>
				</div>
				<SRLWrapper>
					<div className={s.gallery}>
						{data[0] &&
							data[0].map((cur) => (
								<div className={s.gallery_item} key={cur.id}>
									<a
										href={
											"http://alfar.corn.az/public/uploads/img/projects/" +
											cur.image
										}
									>
										<img
											src={
												"http://alfar.corn.az/public/uploads/img/projects/" +
												cur.image
											}
											alt=""
											onLoad={() => setLoading(false)}
											width="100%"
										/>
									</a>
									{loading && <h1>Loading...</h1>}
								</div>
							))}
					</div>
				</SRLWrapper>
			</div>
		</SimpleReactLightbox>
	);
};
export default ReferencesDetail;
