import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getMultiLang as ml } from "../components/MultiLang";
import s from "../styles/Services.module.css";

const ServicesDetail = () => {
	const [data, setData] = useState([]);
	const { url } = useRouteMatch();
	const urlId = url.slice(10);

	useEffect(() => {
		fetch(`http://alfar.corn.az/api/category/${urlId}`)
			.then((res) => res.json())
			.then((result) => setData(result));
	}, [urlId]);

	return (
		<div className={s.single_service}>
			<h1>
				{data[2] &&
					ml(data[2][0].name_en, data[2][0].name_az, data[2][0].name_ru)}
			</h1>
			<div className={s.service_container}>
				{data[0] &&
					data[0].map((cur) => (
						<div className={s.image_container} key={cur.id}>
							<div className={s.img_side}>
								<div className={s.service_img}>
									<LazyLoadImage
										src={
											"http://alfar.corn.az/uploads/img/products/" + cur.image
										}
										alt="Logo"
									/>
								</div>
								<h3>{cur.name_az}</h3>
							</div>
							<p>
								{ml(cur.description_en, cur.description_az, cur.description_ru)}
							</p>
						</div>
					))}
			</div>
		</div>
	);
};

export default ServicesDetail;
