import React, { useState } from "react";
import ReferencesText from "../components/ReferencesText";
import { useQuery } from "react-query";
import { getTrans as t } from "../components/Translation";
import { getMultiLang as ml } from "../components/MultiLang";
import { Link, useRouteMatch } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import s from "../styles/References.module.css";

const fetchProjects = async () => {
	const res = await fetch(`http://alfar.corn.az/api/home`, {
		cacheTime: 60000,
		refetchOnmount: false,
	});

	return res.json();
};

const References = () => {
	const { data } = useQuery("projects", fetchProjects);
	const [loading, setLoading] = useState(true);
	const { url } = useRouteMatch();

	return (
		<div className={s.references}>
			<h1>{t("references")}</h1>
			<div className={s.container}>
				{data &&
					data[2].map((cur) => (
						<div className={s.ref_block} key={cur.id}>
							<div className={s.ref_left}>
								<Link to={`${url}/${cur.id}`}>
									{loading && <h1 className={s.loaded}>Loading...</h1>}
									<LazyLoadImage
										src={
											"http://alfar.corn.az/public/uploads/img/projects/" +
											cur.image
										}
										alt="Logo"
										width="100%"
										onLoad={() => setLoading(false)}
									/>
									<div className={s.effect}></div>
									<div className={s.img_name}>
										{ml(cur.name_en, cur.name_az, cur.name_ru)}
									</div>
								</Link>
							</div>
							<ReferencesText
								name={ml(cur.name_en, cur.name_az, cur.name_ru)}
								place={ml(cur.place_en, cur.place_az, cur.place_ru)}
								customer={ml(cur.customer_en, cur.customer_az, cur.customer_ru)}
								typeOfWorks={ml(
									cur.type_works_en,
									cur.type_works_az,
									cur.type_works_ru
								)}
								periodFrom={cur.period_from}
								periodTo={cur.period_to}
							/>
						</div>
					))}
			</div>
		</div>
	);
};

export default References;
