import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import s from "../../styles/Home.module.css";

const ReferenceSection = ({ id, imgR, imgAlt, nameR }) => {
	return (
		<Link to={"/references/" + id}>
			<div className={s.imgs_container}>
				<LazyLoadImage src={imgR} alt={imgAlt} width="100%" height="100%" />
				<p>{nameR}</p>
			</div>
		</Link>
	);
};

export default ReferenceSection;
